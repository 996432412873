import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../../utils/supabase';
import WebinarVideo from '../../components/WebinarVideo';
import CTAWidgets from '../../components/CTAWidgets';
import CTAPageReveal from '../../components/CTAPageReveal';
import ChatBox from '../../components/ChatBox';
import CTAMarkers from '../../components/CTAMarkers';
import Webcam from '../../components/Webcam';
import WebinarAttendeeCount from '../../components/WebinarAttendeeCount';
import MobileNotification from '../../components/MobileNotification';
import CTAPrompt from '../../components/CTAPrompt';
import { injectHtmlWithInlineJS, sendInteraction } from '../../utils/helpers';
import './index.css';

const Join = ({ replay = false, setHasError }) => {
	const [webinarData, setWebinarData] = useState(null);
	const [minimized, setMinimized] = useState(false);
	const [videoPosition, setVideoPosition] = useState(0);
	const [status, setStatus] = useState(null);
	const { bid } = useParams();

	useEffect(() => {
		// fetch the webinar data
		const fetchWebinarData = async () => {
			await fetch(
				process.env.REACT_APP_SERVER_DOMAIN + '/api/v1/session/' + bid
			)
				.then((res) => res.json())
				.then((data) => {
					setWebinarData(data.booking);
				})
				.catch((error) => {
					console.log(error);
					setHasError(true);
				});
		};

		fetchWebinarData();
	}, []);

	useEffect(() => {
		// embed any page scripts
		if (webinarData && webinarData.settings?.embed_script) {
			injectHtmlWithInlineJS(
				'load',
				webinarData.settings.embed_script,
				webinarData
			);
		}
	}, [webinarData]);

	useEffect(() => {
		// Video is loaded, so let's call that an attendance
		if (webinarData && (status === 'live' || replay)) {
			if (webinarData && webinarData.settings?.embed_script_attend) {
				injectHtmlWithInlineJS(
					'attend',
					webinarData.settings.embed_script_attend,
					webinarData
				);
			}
			sendInteraction(
				'attend',
				webinarData.booking_id,
				webinarData.webinar_id
			);
		}
	}, [status]);

	useEffect(() => {
		// Every minute, send a watching interaction
		if (webinarData && videoPosition % 60 === 0) {
			sendInteraction(
				`watching${replay ? '-replay' : ''}`,
				webinarData.booking_id,
				webinarData.webinar_id,
				{
					value_int: parseInt(videoPosition / 60)
				}
			);
		}
	}, [videoPosition]);

	useEffect(() => {
		// set the window title
		if (webinarData && webinarData?.title) {
			document.title = `${webinarData.title} | AlexellWebinar`;

			// set the meta description
			const metaDesc = document.querySelector('meta[name="description"]');
			if (metaDesc) {
				metaDesc.setAttribute('content', webinarData.description);
			}
		}
	}, [webinarData]);

	useEffect(() => {
		// Work out if the webinar is live, finished or early, every second
		const interval = setInterval(() => {
			if (webinarData) {
				const now = new Date();
				const start_time = new Date(webinarData.start_time);
				const end_time = new Date(
					new Date(webinarData.start_time).getTime() +
						webinarData.length * 1000
				);

				if (replay) {
					setStatus('replay');
				}
				if (now < start_time) {
					setStatus('early');
				} else if (now > end_time) {
					setStatus('finished');
				} else {
					setStatus('live');
				}
			}
		}, 1000);
		//Clearing the interval
		return () => clearInterval(interval);
	}, [webinarData]);

	useEffect(() => {
		// Redirect if the webinar is finished, and we didn't finished up minimized
		if (
			status === 'finished' &&
			!replay &&
			webinarData.settings.complete_redirect_url &&
			!minimized
		) {
			setTimeout(function () {
				window.location.href =
					webinarData.settings.complete_redirect_url;
			}, 5000);
		}
	}, [status]);

	return (
		<div className={`webinar-container ${minimized ? 'minimized' : ''}`}>
			<div className={`video-section ${minimized ? 'minimized' : ''}`}>
				{webinarData && (
					<WebinarVideo
						minimized={minimized}
						status={status}
						start_time={replay ? null : webinarData.start_time}
						vimeo_id={webinarData.vimeo_id}
						replay={replay}
						settings={webinarData.settings}
						booking_id={bid}
						webinar_id={webinarData.webinar_id}
						videoTimer={(player) => {
							setVideoPosition(parseInt(player.playedSeconds));
						}}
					/>
				)}
				{webinarData &&
					!minimized &&
					!replay &&
					webinarData.settings.request_webcam === 'True' && (
						<Webcam />
					)}
			</div>
			<div className={`chat-section ${minimized ? 'minimized' : ''}`}>
				{webinarData && (
					<>
						{webinarData.settings.show_mobile_warning ===
							'True' && <MobileNotification />}
						{status === 'live' && !replay && (
							<WebinarAttendeeCount />
						)}
						<CTAWidgets
							key={`${webinarData.webinar_id}-ctas`}
							timecode={videoPosition}
							webinar_id={webinarData.webinar_id}
							replay={replay}
							booking_id={bid}
							booking={webinarData}
							ctas={webinarData.ctas}
							hs_list_proof={webinarData.settings.hs_list_proof}
							actionText={webinarData.settings.proof_action_text}
						/>
						<ChatBox
							key={`${webinarData.webinar_id}-chat`}
							replay={replay}
							booking_id={bid}
							webinar_id={webinarData.webinar_id}
							from_name={webinarData.first_name}
							session_time={webinarData.start_time}
							timecode={videoPosition}
							ctaMessages={webinarData.ctas.filter((cta) => {
								return cta.widget_type === 'chat';
							})}
							askMaax={
								webinarData.settings.maax_active === 'True'
							}
							reuse={
								webinarData.settings.reuse_chat === 'True'
									? webinarData.chat_messages
									: []
							}
						/>
					</>
				)}
			</div>
			{webinarData && (
				<CTAPageReveal
					timecode={videoPosition}
					webinar_id={webinarData.webinar_id}
					replay={replay}
					booking_id={bid}
					ctas={webinarData.ctas}
					hs_list_proof={webinarData.settings.hs_list_proof}
					trigger={setMinimized}
					booking={webinarData}
					actionText={webinarData.settings.proof_action_text}
				/>
			)}

			{webinarData && (
				<CTAMarkers
					ctas={webinarData.ctas}
					booking={webinarData}
					timecode={videoPosition}
					replay={replay}
				/>
			)}

			{webinarData && (
				<CTAPrompt
					ctas={webinarData.ctas}
					booking={webinarData}
					timecode={videoPosition}
					replay={replay}
				/>
			)}
		</div>
	);
};

export default Join;
