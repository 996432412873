import { sendInteraction, injectHtmlWithInlineJS } from '../../utils/helpers';
import useWebinarStore from '../../utils/store';
const Swal = require('sweetalert2');

const CTAPrompt = ({ ctas, booking, timecode, moderator = false, replay }) => {
	const activatedCtas = useWebinarStore((state) => state.activatedCtas);

	for (let i = 0; i < ctas.length; i++) {
		//if (moderator) return;

		if (
			!activatedCtas.includes(ctas[i].id) &&
			ctas[i].widget_type === 'prompt' &&
			ctas[i].show_time == timecode &&
			(!replay || ctas[i].show_on_replay)
		) {
			useWebinarStore.setState((state) => ({
				activatedCtas: [...state.activatedCtas, ctas[i].id]
			}));

			// Load any scripts
			if (ctas[i].embed_script) {
				injectHtmlWithInlineJS(
					ctas[i].id,
					ctas[i].embed_script,
					booking
				);
			}

			// Send interaction
			sendInteraction(
				'cta_viewed',
				booking.booking_id,
				booking.webinar_id,
				{
					value_string: ctas[i].id
				}
			);

			Swal.fire({
				title: 'Viewer Survey',
				text: ctas[i].chat_message,
				input: 'text',
				inputAttributes: {
					autocapitalize: 'off'
				},
				showCancelButton: true,
				confirmButtonText: 'Send Feedback',
				showLoaderOnConfirm: true,
				preConfirm: async (answer) => {
					try {
						// Send answer as interaction
						sendInteraction(
							'cta_prompt_answered',
							booking.booking_id,
							booking.webinar_id,
							{
								value_string: JSON.stringify({
									cta_id: ctas[i].id,
									answer
								})
							}
						);

						Swal.fire({
							icon: 'success',
							title: 'Thanks for your feedback!',
							showConfirmButton: false,
							timer: 1000
						});

						console.log('CTA Prompt Answered', ctas[i].id);
					} catch (error) {
						Swal.showValidationMessage(`
                      Submission failed: ${error}
                    `);
					}
				},
				allowOutsideClick: () => !Swal.isLoading()
			});

			console.log('CTA Prompt Shown:', ctas[i].id);
		}
	}

	return null;
};

export default CTAPrompt;
